import { useEffect, useState } from "react";
import { Modal, Upload, Button, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import * as XLSX from "xlsx";
import { Link } from "react-router-dom";
import saveAs from "file-saver";
import * as ExcelJS from "exceljs";
import "./index.css"; // Import the CSS file
import { postApi } from "redux/apis";
import { getWarrantyRulesActionTable } from "redux/action/warrantyRulesActions";
import { useDispatch } from "react-redux";
import { AppDispatch } from "redux/store";
import { getWarrantyDetailsActionTable } from "redux/action/productWarrantyDetailsAction";

interface UploadFileFormProps {
  setIsModalVisible: (visible: boolean) => void;
  ModalDetails: any;
  isModalVisible: boolean;
  searchParams: any;
  pageSize: any;
  currentPage: any;
}

const FileUploadModal = ({
  isModalVisible,
  setIsModalVisible,
  ModalDetails,
  searchParams,
  pageSize,
  currentPage,
}: UploadFileFormProps) => {
  const [fileList, setFileList] = useState<any[]>([]);
  const [fileData, setFileData] = useState<any[]>([]);
  const dispatch = useDispatch<AppDispatch>();

  const downloadSampleFile = async () => {
    // Create a new workbook
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("SampleData");

    // Add data to the worksheet
    const extendWarrantyData = [
      ["New Warranty Expiration Date", "Item", "Serial Number"],
      ["7/9/2026", "POS-SC100", "8532110051021423"],
      ["7/9/2026", "POS-RPT006S", "00008532110050863692"],
    ];

    const importRulesData = [
      [
        "Effective Date",
        "Product Category",
        "Country ISO Code",
        "Customer",
        "Product",
        "Warranty Month",
        "Comments",
      ],
      [
        "2024-09-01",
        "Category1",
        "US",
        "Customer1",
        "Product1",
        12,
        "No comments",
      ], // Example row
    ];

    const data =
      ModalDetails.Name === "Import Extended Warranty"
        ? extendWarrantyData
        : importRulesData;
    // Append data rows
    data.forEach((row) => {
      worksheet.addRow(row);
    });

    // Style the header (first row)
    const headerRow = worksheet.getRow(1);
    headerRow.font = { bold: true }; // Make font bold

    headerRow.eachCell({ includeEmpty: true }, (cell) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFD3D3D3" },
      };
    });

    // Write the Excel file to a blob
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    const fileName =
      ModalDetails.Name === "Import Extended Warranty"
        ? "3nStar Extend Warranty.xlsx"
        : "3nStar Import Rules.xlsx";
    // Save the file with a custom name
    saveAs(blob, fileName);
  };

  const handleOk = async () => {
    if (fileList.length === 0) {
      message.error("Please select a file to upload.");
      return;
    }

    const reader = new FileReader();

    reader.onload = (e: any) => {
      const binaryStr = e.target.result;
      const workbook = XLSX.read(binaryStr, { type: "binary" });
      const jsonData = XLSX.utils.sheet_to_json(
        workbook.Sheets[workbook.SheetNames[0]]
      );
      console.log(jsonData);
      setFileData(jsonData); // JSON data parsed from the file
      setIsModalVisible(false); // Close modal after upload
      setFileList([]); // Clear the file list
    };

    reader.readAsArrayBuffer(fileList[0]); // Use the original file object here
  };

  useEffect(() => {
    const fetchData = async () => {
      if (fileData.length > 0) {
        // File data has been updated, now you can log or run further actions
        console.log("Updated fileData:", fileData);
        try {
          // Check if fileData has entries
          if (fileData.length > 0) {
            // Map through the fileData and filter based on required properties

            // If there's valid mapped data, proceed with API call
            // Choose the API endpoint based on ModalName
            if (ModalDetails.Name === "Import Extended Warranty") {
              const apiUrl = "/productWarrantyDetails/extend-warranty-via-file";
              const mappedData = fileData
                .map((item) => {
                  const {
                    Item: productName,
                    "New Warranty Expiration Date": newExpiryDate,
                    "Serial Number": serialNumber,
                  } = item;

                  // Check if all required properties exist and are valid
                  if (serialNumber && productName && newExpiryDate) {
                    return {
                      serialNumber,
                      productName,
                      newExpiryDate: new Date(newExpiryDate), // Convert to Date object
                    };
                  } else {
                    console.warn(
                      "Missing required fields in some entries",
                      item
                    );
                    return null; // Skip invalid entries
                  }
                })
                .filter(Boolean);

              if (mappedData.length > 0) {
                const response = await postApi(apiUrl, mappedData);
                console.log("API response:", response);
                if (response.status === 200) {
                  message.success("Warranty dates extended successfully");

                  getWarrantyDetailsActionTable({
                    page: currentPage, // Page number
                    limit: pageSize, // Page size or number of results per page
                    searchParams, // Search params
                  });
                }
              }
            } else if (ModalDetails.Name === "Import Rules") {
              const apiUrl = "/warranty-rules/bulk-add";

              const mappedData = fileData
                .map((item) => {
                  const {
                    "Effective Date": effectiveDate,
                    "Product Category": productCategory,
                    "Country ISO Code": countryISOCode,
                    Customer: customer,
                    Product: product, // Assuming this is the correct field for the product name
                    "Warranty Month": warrantyMonth,
                    Comments: comments,
                  } = item;
                  // Check if all required properties exist and are valid
                  if (
                    effectiveDate &&
                    (productCategory ||
                      countryISOCode ||
                      customer ||
                      product) &&
                    warrantyMonth
                  ) {
                    return {
                      effectiveDate: new Date(effectiveDate),
                      productCategory,
                      countryISOCode,
                      customer,
                      product,
                      warrantyMonth: String(warrantyMonth),
                      comments,
                    };
                  } else {
                    console.warn(
                      "Missing required fields in some entries",
                      item
                    );
                    return null; // Skip invalid entries
                  }
                })
                .filter(Boolean);

              const response = await postApi(apiUrl, mappedData);
              console.log("API response:", response);
              if (response.status === 200) {
                message.success("rules imported successfully");

                dispatch(
                  getWarrantyRulesActionTable({
                    page: currentPage,
                    limit: pageSize,
                    searchParams,
                  })
                );
              }
            }

            // Send the JSON data to the chosen API
          } else {
            console.error("File data is empty.");
          }
        } catch (error) {
          console.error("An error occurred:", error);
        }
      }
    };

    fetchData();
  }, [fileData]);

  const handleCancel = () => {
    setIsModalVisible(false); // Close modal
    setFileList([]); // Clear the file list
  };

  const uploadProps = {
    beforeUpload: (file: any) => {
      const isCSV = file.type === "text/csv";
      const isExcel =
        file.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        file.type === "application/vnd.ms-excel";

      if (!isCSV && !isExcel) {
        message.error("You can only upload CSV or Excel file!");
        return Upload.LIST_IGNORE; // Prevent the file from being uploaded
      }

      // Only allow a single file
      setFileList([file]); // Update file list with the selected file
      return false; // Prevent automatic upload
    },
    onRemove: (file: any) => {
      setFileList((prevFileList) =>
        prevFileList.filter((item) => item.uid !== file.uid)
      ); // Remove file from list
    },
    fileList: fileList,
  };

  return (
    <Modal
      title={ModalDetails.Name}
      open={isModalVisible}
      style={{ top: 50 }}
      footer={
        <div style={{ textAlign: "right", marginTop: 20 }}>
          {/* Add HTML and classes for styling */}
          <Button
            key="back"
            onClick={handleCancel}
            className="custom-cancel-btn"
            size="large"
            htmlType="button"
          >
            Cancel
          </Button>
          <Button
            key="submit"
            type="primary"
            onClick={handleOk}
            className="custom-submit-btn"
            size="large"
            htmlType="button"
          >
            Submit
          </Button>
        </div>
      }
      onCancel={handleCancel}
    >
      <Link
        onClick={downloadSampleFile}
        target="_blank"
        to={""}
      >
        Download Sample File
      </Link>
      <Upload.Dragger
        {...uploadProps}
        showUploadList={false}
      >
        <p>
          <UploadOutlined className="ant-upload-drag-icon" />
        </p>
        <p className="ant-upload-text">
          Drag & drop a file here, or click to select
        </p>
        <p className="ant-upload-hint">Supports CSV and Excel file</p>
      </Upload.Dragger>
      {fileList.length > 0 && (
        <div style={{ marginTop: 16 }}>
          <strong>Selected File:</strong> {fileList[0].name}
        </div>
      )}
    </Modal>
  );
};

export default FileUploadModal;
