import { ChangeEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getActiveConnectionAction } from "redux/action/activeConnectionsActions";
import { AppDispatch } from "redux/store";
import NewCompany from "../Home/NoCompany/newCompany";
import { Tag, Table, Space } from "antd";
import { EditOutlined } from "@ant-design/icons"; // For edit icon
import moment from "moment"; // For date formatting
import SearchAndFilter from "pages/Home/SearchAndFilter";
import ExtendWarrantyModal from "./ExtendWarrantyModal";
import ExtendWarrantyDrawer from "./ExtendWarrantyDrawer";
import { getWarrantyDetailsActionTable } from "redux/action/productWarrantyDetailsAction";
const Sales = () => {
  const dispatch = useDispatch<AppDispatch>();

  const { productWarrantyDetails: ProductWarrantyDetails, totalRecords } =
    useSelector((state: any) => state.ProductWarrantyDetails.data || {});

  const { isLoading } = useSelector(
    (state: any) => state.ProductWarrantyDetails
  );

  const [isModalVisible, setIsModalVisible] = useState(false);

  const allActiveConnection = useSelector(
    (state: any) => state?.ActiveServices?.data
  );

  // const handleExtendWarranty = () => {
  //   setIsModalVisible(true);
  // };

  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const [dataSource, setDataSource] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [searchFields, setSearchFields] = useState<string[]>([
    "invoiceNumber",
    "customerName",
  ]);
  const [formData, setFormData] = useState<any>(null);

  // Handlers
  const paginationChangeHandler = (pageNo: number, pageSize: number) => {
    setCurrentPage(pageNo);
    setPageSize(pageSize);
  };

  const performSearchHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchValue(value);
    setCurrentPage(1);
  };

  const resetButtonHandler = () => {
    setSearchValue("");
    setSearchFields(["invoiceNumber", "customerName"]);
    setCurrentPage(1);
  };

  const searchFieldsSelectHandler = (value: string[]) => {
    setSearchFields(value);
    setCurrentPage(1);
  };

  const [isDrawerVisible, setIsDrawerVisible] = useState(false);

  const handleSave = (data: any) => {
    // Handle save logic here
    console.log("Saving data:", data);
    dispatch(
      getWarrantyDetailsActionTable({
        pageNumber: currentPage,
        pageSize,
        searchParams: { fields: searchFields, value: searchValue },
      })
    );
  };

  const handleEditClick = (record: any) => {
    setFormData(record); // Set the form data based on the selected row
    setIsDrawerVisible(true); // Open the drawer
  };

  // Fetch data from Redux action
  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(getActiveConnectionAction());
        console.log("params", currentPage, pageSize, searchFields, searchValue);
        const xyz = await dispatch(
          getWarrantyDetailsActionTable({
            pageNumber: currentPage,
            pageSize,
            searchParams: { fields: searchFields, value: searchValue },
          })
        );
        console.log("xyz", xyz);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData(); // Fetch data on component mount
  }, [dispatch, pageSize, currentPage, searchValue, formData]);

  // const sampleData: any = [
  //   {
  //     invoiceNumber: "INV001",
  //     invoiceDate: new Date("10/08/2022"),
  //     warrantyExpirationDate: new Date("10/08/2025"),
  //     customerName: "John Doe",
  //     itemName: "Smartphone",
  //     itemDescription: "Latest model with 128GB storage",
  //     itemSerialNumber: "SN123456789",
  //     status: "In Warranty",
  //   },
  //   {
  //     invoiceNumber: "INV002",
  //     invoiceDate: new Date("10/08/2022"),
  //     warrantyExpirationDate: new Date("10/08/2024"),
  //     customerName: "Jane Smith",
  //     itemName: "Laptop Pro",
  //     itemDescription: "16GB RAM, 512GB SSD",
  //     itemSerialNumber: "SN987654321",
  //     status: "Out of Warranty",
  //   },
  //   // Add more sample data as needed
  // ];
  useEffect(() => {
    console.log("ProductWarrantyDetails", ProductWarrantyDetails);
    if (ProductWarrantyDetails) {
      setDataSource(ProductWarrantyDetails);
    }
  }, [ProductWarrantyDetails]);

  // Columns configuration for Ant Design Table
  const columns = [
    {
      title: "3nStar Invoice Number",
      dataIndex: "invoiceNumber",
      key: "invoiceNumber",
      width: 140,
    },
    {
      title: "3nStar Invoice Date",
      dataIndex: "invoiceDate",
      key: "invoiceDate",
      width: 140,
      render: (text: string) => moment(text).format("MM/DD/YYYY"),
    },
    {
      title: "Item Serial Number",
      dataIndex: "serialNumber",
      key: "itemSerialNumber",
    },
    {
      title: "Customer Name",
      dataIndex: "customerName",
      key: "customerName",
    },
    {
      title: "Item Name",
      dataIndex: "itemName",
      key: "itemName",
    },
    {
      title: "Item Description",
      dataIndex: "itemDesc",
      key: "itemDescription",
    },
    {
      title: "Warranty Expiration Date",
      dataIndex: "expiryDate",
      key: "warrantyExpirationDate",
      width: 120,
      render: (text: string, record: any) => {
        const formattedDate = moment(text).format("MM/DD/YYYY");
        return (
          <div>
            <span>{formattedDate}</span>
            <br /> {/* Line break to separate date and tag */}
            {record.isExtendWarranty ? (
              <Tag
                color="green"
                style={{ fontWeight: "bold" }}
              >
                Extended Warranty
              </Tag>
            ) : (
              <Tag color="orange">{record.ruleName}</Tag>
            )}
          </div>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "isExpired",
      key: "status",
      width: 120,
      render: (status: boolean) => (
        <Tag color={!status ? "green" : "red"}>
          {!status ? "In Warranty" : "Expired"}
        </Tag>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      width: 100,
      render: (text: any, record: any) => (
        <Space size="middle">
          <EditOutlined
            style={{ color: "black", cursor: "pointer", fontSize: "18px" }}
            onClick={() => handleEditClick(record)}
          />
        </Space>
      ),
    },
  ];

  const options: { label: string; value: string }[] = columns
    .filter(
      (column) =>
        ![
          "Actions",
          "Status",
          "Warranty Expiration Date",
          "3nStar Invoice Date",
        ].includes(column.title)
    )
    .map((column: any) => ({
      label: column.title,
      value: column.dataIndex,
    }));

  return (
    <>
      {allActiveConnection &&
      allActiveConnection.length > 0 &&
      allActiveConnection[0].isActiveConnection ? (
        <div>
          {/* Extend Warranty Button */}

          {/* Sales data for specific company */}
          <p
            style={{
              margin: "10px 0px",
              fontSize: "16px",
            }}
          >
            Sales data for:&nbsp;
            <span
              style={{
                fontWeight: "bolder",
              }}
            >
              {allActiveConnection[0].companyName}
            </span>
          </p>

          <div style={{ backgroundColor: "white" }}>
            <SearchAndFilter
              performSearchHandler={performSearchHandler}
              searchValue={searchValue}
              PageSize={pageSize}
              searchFieldsSelectHandler={searchFieldsSelectHandler}
              resetButtonHandler={resetButtonHandler}
              searchFields={searchFields}
              handleExtendWarranty={setIsModalVisible}
              menuName="Sales"
              options={options}
            />

            <ExtendWarrantyModal
              isModalVisible={isModalVisible}
              setIsModalVisible={setIsModalVisible}
              ModalDetails={{ Name: "Import Extended Warranty" }}
              currentPage={currentPage}
              pageSize={pageSize}
              searchParams={{ fields: searchFields, value: searchValue }}
            />

            <ExtendWarrantyDrawer
              visible={isDrawerVisible}
              onClose={() => {
                setIsDrawerVisible(false);
              }}
              formData={formData}
              onSave={handleSave}
            />

            {/* Dynamic Table */}
            <Table
              columns={columns}
              dataSource={dataSource}
              loading={isLoading}
              pagination={{
                current: currentPage,
                pageSize: pageSize,
                total: totalRecords,
                onChange: paginationChangeHandler,
              }}
              rowKey="id"
            />
          </div>
        </div>
      ) : (
        <NewCompany />
      )}
    </>
  );
};

export default Sales;
